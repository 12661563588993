import React from 'react';
import { Spin } from 'antd';
import style from './faq.module.scss';
import { Layouts } from '../components/components';
import { Promo, SliderBlock, PictureWithText } from '../modules/modules';
import { SliderBlocksType, PictureWithTextType, PromoBlockType } from '../lib/constants/enums';
import { StrapiBlocksName, StrapiMainPagesNames } from '../lib/constants/strapi';

import { useStrapiPage } from '../lib/hooks/useStrapiPage';
import { replaceStrapiComponentName } from '../lib/utils';

const PartnersPage: React.FC = () => {
  const { IndexLayout, LayoutBG } = Layouts;
  const { currentPage } = useStrapiPage(StrapiMainPagesNames.partners);
  return (
    <IndexLayout>
      {currentPage && currentPage.blocks ? (
        currentPage.blocks.map((block, index) => {
          switch (replaceStrapiComponentName(block.__component)) {
            case StrapiBlocksName.partnersSplash:
              return (
                <Promo
                  data={{ title: block?.title, description: block?.description, additionalDescription: block?.additionalDescription }}
                  type={PromoBlockType.partners}
                  imagePosition="right"
                  key={index}
                />
              );
            case StrapiBlocksName.partnersInfo:
              return (
                <React.Fragment key={index}>
                  <LayoutBG className={style.bg}>
                    <>
                      <SliderBlock data={{ ...block, title: block?.firstTitle }} type={SliderBlocksType.reviewImage} key={index} />
                    </>
                    <>
                      <SliderBlock data={{ ...block, title: block?.secondTitle }} type={SliderBlocksType.reviews} key={index} />
                    </>
                  </LayoutBG>
                </React.Fragment>
              );
            case StrapiBlocksName.blockWithCircle:
              return <PictureWithText data={block} type={PictureWithTextType.partners} key={index} />;
            default:
              return null;
          }
        })
      ) : (
        <div className={style.loader}>
          <Spin />
        </div>
      )}
    </IndexLayout>
  );
};

export default PartnersPage;
